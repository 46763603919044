import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../componentes/Headers/Header";
import Footer from "../../componentes/footer/Footer";
import HantecPopup from "../../componentes/blog/popup";
import imgCompressor from "../../assets/bg-compressor.webp";
import imgAlineadoras from "../../assets/products/alineadoras/test_ali2.webp";
import imgBienvenida from "../../assets/blog/bienvenida.webp";
import imgBienvenidaMobile from "../../assets/blog/bienvenida_min.webp";
import imgClaveExitoMin from "../../assets/blog/clave_exito_min.webp";
import "react-toastify/dist/ReactToastify.css";
import RelatedArticles from "../../componentes/blog/RelatedArticles";
import CallToAction from "../../componentes/blog/CallToAction";

const BlogPostTemplate = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const relatedArticles = [
    {
      title: "La Clave del Éxito en Ventas",
      description: "Actitud positiva y persistencia...",
      image: imgClaveExitoMin,
      href: "/blog/autozone/2",
      buttonText: "Leer Más",
    },
    {
      title: "Compresores",
      description:
        "Libera el poder del aire comprimido con una eficiencia y un rendimiento inigualables...",
      image: imgCompressor,
      buttonText: "Leer Más",
      href: "/force_element",
    },
    {
      title: "Alineadoras",
      description: "Optimiza tu taller con alineadoras de dirección...",
      image: imgAlineadoras,
      buttonText: "Leer Más",
      href: "/alineadoras/autozone",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const toastError = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto px-4 pb-4">
        <div className="bg-blue-600 w-28 rounded-lg">
          <div className="flex mb-2 w-auto">
            <ChevronLeft className="my-auto w-6 h-6 text-white" />
            <button
              onClick={() => navigate("/blog/autozone")}
              className="text-muted-foreground text-lg hover:underline text-white"
            >
              Regresar
            </button>
          </div>
        </div>
        {/* Title Section */}
        <header className="mb-8">
          <h1 className="text-4xl font-bold mb-4 leading-tight">
            {content.title}
          </h1>
          <div className="flex items-center text-gray-600 text-sm">
            <span className="flex items-center mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {content.readTime}
            </span>
            <span>{content.date}</span>
          </div>
        </header>

        {/* Introduction */}
        <p className="text-lg mb-8">{content.introduction}</p>

        {/* Main Content */}
        <article className="space-y-8">
          {/* Featured Image */}

          {/* Mobile */}
          {/* <img
            src={imgBienvenidaMobile}
            alt="Banner de bienvenida"
            className="block md:hidden w-full rounded-lg"
          /> */}

          <div className="block md:hidden flex justify-center mb-4">
            <img
              src={imgBienvenidaMobile}
              alt="Éxito en Ventas con Hantec"
              className="object-cover object-top rounded-lg w-full h-40"
            />
          </div>

          {/* Desktop */}
          <img
            src={imgBienvenida}
            alt="Banner de bienvenida"
            className="hidden md:block w-full rounded-lg"
          />

          {/* Content Sections */}
          {content.sections.map((section, index) => (
            <section key={index}>
              <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
              {section.paragraphs.map((paragraph, pIndex) => (
                <p key={pIndex} className={pIndex > 0 ? "mt-4" : ""}>
                  {paragraph}
                </p>
              ))}
            </section>
          ))}

          {/* How to Sell Section */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              {content.howToSell.title}
            </h2>
            <p className="mb-6">{content.howToSell.description}</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {content.howToSell.points.map((point, index) => (
                <div key={index} className="border rounded-lg p-4">
                  <h3 className="text-lg font-semibold mb-2 flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2 text-green-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {point.title}
                  </h3>
                  <p>{point.description}</p>
                </div>
              ))}
            </div>
          </section>

          {/* Call to Action */}
          <CallToAction
            title={content.cta.title}
            subtitle={content.cta.description}
          />
        </article>

        {/* Related Articles */}

        <RelatedArticles relatedArticles={relatedArticles} />

        {/* Newsletter Subscription */}
        <section className="mt-12 bg-gray-100 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold mb-4">
            {content.newsletter.title}
          </h2>
          <p className="mb-4">{content.newsletter.description}</p>
          <div className="flex gap-4">
            {/* <input
                type="email"
                placeholder={content.newsletter.inputPlaceholder}
                className="flex-grow border rounded-lg px-4 py-2"
            /> */}
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
            >
              {content.newsletter.buttonText}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 inline-block ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </button>
          </div>
        </section>
      </div>
      <Footer />
      <HantecPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toastSuccess={toastSuccess}
        toastError={toastError}
      />
      <ToastContainer />
    </>
  );
};

export default BlogPostTemplate;
