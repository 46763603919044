import "../../assets/style/landing_style.css";
import Footer from "../../componentes/footer/Footer";
import Header from "../../componentes/Headers/Header";
import ProductCard from "../../componentes/landingProducts/compressor/ProductCard";
import TblTechnicalSpecifications from "../../componentes/landingProducts/compressor/TblTechnicalSpecifications";
import { SnipTrafficPlatform } from "../../utils/Analitycs/analytics";
import { dateDiffFromNow } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import imgBackground from "../../assets/bg-compressor.webp";
import imgCompresor from "../../assets/pieza_compresor.webp";
import imgFiltroAts from "../../assets/filtro_ats.webp";
import imgSistemaCompresionAire from "../../assets/sistema_compresion_aire.webp";
import imgTanqueCompresor from "../../assets/img_compresor_ref.webp";
import imgCertificados from "../../assets/certificados.webp";
import imgDesmontadoras from "../../assets/products/desmontadoras/bg_main.webp";
import imgAlineadoras from "../../assets/products/alineadoras/test_ali2.webp";
import imgBienvenidaMin from "../../assets/blog/bienvenida_min.webp";
import { useEffect, useRef, useState } from "react";
import CallToAction from "../../componentes/blog/CallToAction";
import RelatedArticles from "../../componentes/blog/RelatedArticles";

function LandingCompressor() {
  const location = useLocation();
  const scrollRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 2));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(products.length - 2, prevIndex + 2)
    );
  };

  const products = [
    {
      created_at: "2024-03-14T17:44:37.805361Z",
      id_odoo: 1917,
      kg: 1,
      id_producto: 119,
      product_name: "	ForceElement HFT9X927",
      stock: {
        mostrado: 1,
        disponible: 3,
      },
      t_envio: "castores",
      precio: {
        cantidad: 53462.11,
        moneda: "MXN",
      },
      ancho: 105.0,
      precio_autozone: {
        cantidad: 48132.37,
        moneda: "MXN",
      },
      msi: 0,
      updated_at: "2024-08-26T17:07:48.315138Z",
      descuento: 14.97,
      cubre_envio: 2800,
      unidad_medida: "kg",
      largo: 105.0,
      gifts: null,
      categorias_venta: {
        mas_vendidos: false,
        ofertas_temporada: false,
      },
      precio_sin_iva: {
        cantidad: 49627.02,
        moneda: "MXN",
      },
      categoria: "Compresores",
      peso: 130.0,
      titulo: "Compresor Tornillo Forceelement 5Hp Con Tanque",
      envio_gratis: false,
      precio_habitual: {
        cantidad: 57567.34,
        moneda: "MXN",
      },
      sku: "HFT9X927",
      p_envio: 0,
      etiquetas: ["Industria", "Llantera"],
      precio_az_sin_iva: {
        cantidad: 41493.42,
        moneda: "MXN",
      },
      alto: 60.0,
      imagen:
        "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/927_Mesadetrabajo1.jpg2024-03-20%2019%3A04%3A23.412076",
      href: "https://hantec.us/product/compresor-tornillo-forceelement-5hp-con-tanque/119",
    },

    {
      created_at: "2024-03-14T17:44:37.706032Z",
      id_odoo: 1918,
      kg: 1,
      id_producto: 118,
      product_name: "ForceElement HFT9X937",
      stock: {
        mostrado: 1,
        disponible: 10,
      },
      t_envio: "castores",
      precio: {
        cantidad: 83132.13,
        moneda: "MXN",
      },
      ancho: 105.0,
      precio_autozone: {
        cantidad: 68000,
        moneda: "MXN",
      },
      msi: "0",
      updated_at: "2024-08-01T09:27:02.624222Z",
      descuento: 14.97,
      cubre_envio: 3800,
      unidad_medida: "kg",
      largo: 105.0,
      gifts: null,
      categorias_venta: {
        mas_vendidos: false,
        ofertas_temporada: false,
      },
      precio_sin_iva: {
        cantidad: 65577.1,
        moneda: "MXN",
      },
      categoria: "Compresores",
      peso: 170.0,
      titulo: "Compresor Tornillo Force Element 5HP Con Tanque y Secador",
      envio_gratis: false,
      precio_habitual: {
        cantidad: 89457.66,
        moneda: "MXN",
      },
      sku: "HFT9X937",
      p_envio: 0,
      etiquetas: ["Llantera", "Industria", "Hojalatería y Pintura"],
      precio_az_sin_iva: {
        cantidad: 58620.69,
        moneda: "MXN",
      },
      alto: 60.0,
      imagen:
        "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/Copiade937_Mesadetrabajo1.jpg2024-03-20%2018%3A45%3A03.385357",
      href: "https://hantec.us/product/compresor-tornillo-force-element-5hp-con-tanque-y-secador/118",
    },

    {
      created_at: "2024-03-14T17:44:37.603990Z",
      id_odoo: 1919,
      kg: 1,
      id_producto: 117,
      product_name: "ForceElement HFT9X910",
      stock: {
        mostrado: 1,
        disponible: 3,
      },
      t_envio: "castores",
      precio: {
        cantidad: 127521.66,
        moneda: "MXN",
      },
      ancho: 163.0,
      precio_autozone: {
        cantidad: 109284.92,
        moneda: "MXN",
      },
      msi: 0,
      updated_at: "2024-08-01T09:27:02.415936Z",
      descuento: 14.97,
      cubre_envio: 4800,
      unidad_medida: "kg",
      largo: 148.0,
      gifts: null,
      categorias_venta: {
        mas_vendidos: false,
        ofertas_temporada: false,
      },
      precio_sin_iva: {
        cantidad: 98383.15,
        moneda: "MXN",
      },
      categoria: "Compresores",
      peso: 360.0,
      titulo:
        "Compresor Tornillo Forceelement 10Hp Todo En Uno Con Tanque, Secador y Separador de Partículas",
      envio_gratis: false,
      precio_habitual: {
        cantidad: 134210.36,
        moneda: "MXN",
      },
      sku: "HFT9X910",
      p_envio: 0,
      etiquetas: ["Industria", "Hojalatería y Pintura"],
      precio_az_sin_iva: {
        cantidad: 94211.14,
        moneda: "MXN",
      },
      alto: 85.0,
      imagen:
        "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/910_Mesadetrabajo1.jpg2024-03-20%2023%3A29%3A45.770458",
      href: "https://hantec.us/product/compresor-tornillo-forceelement-10hp-todo-en-uno-con-tanque,-secador-y-separador-de-part%C3%ADculas/117",
    },

    {
      id_odoo: 1920,
      kg: 1,
      id_producto: 116,
      product_name: "ForceElement HFT9X920",
      stock: {
        mostrado: 1,
        disponible: 3,
      },
      t_envio: "castores",
      precio: {
        cantidad: 157554.33,
        moneda: "MXN",
      },
      precio_autozone: {
        cantidad: 136803.87,
        moneda: "MXN",
      },
      msi: 0,
      descuento: 14.97,
      cubre_envio: 5800,
      unidad_medida: "kg",
      largo: 190.0,
      precio_sin_iva: {
        cantidad: 145424.21,
        moneda: "MXN",
      },
      categoria: "Compresores",
      titulo:
        "Compresor Tornillo Forceelement 20Hp Todo En Uno Con Tanque, Secador y Separador de Partículas",
      envio_gratis: false,
      precio_habitual: {
        cantidad: 168692.08,
        moneda: "MXN",
      },
      sku: "HFT9X920",
      p_envio: 0,
      precio_az_sin_iva: {
        cantidad: 117934.37,
        moneda: "MXN",
      },
      imagen:
        "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/920_Mesadetrabajo1.jpg2024-03-20%2022%3A18%3A22.789993",
      href: "https://hantec.us/product/compresor-tornillo-forceelement-20hp-todo-en-uno-con-tanque,-secador-y-separador-de-part%C3%ADculas/116",
    },

    {
      id_odoo: 1921,
      id_producto: 109,
      product_name: "ForceElement HFT9X930",
      stock: {
        mostrado: 1,
        disponible: 3,
      },
      precio: {
        cantidad: 210607.19,
        moneda: "MXN",
      },
      precio_autozone: {
        cantidad: 170000,
        moneda: "MXN",
      },
      msi: 0,
      descuento: 14.97,
      cubre_envio: 6700,
      precio_sin_iva: {
        cantidad: 194293.28,
        moneda: "MXN",
      },
      titulo:
        "Compresor Tornillo Forceelement 30Hp Todo En Uno Con Tanque, Secador y Separador de Partículas",
      envio_gratis: false,
      precio_habitual: {
        cantidad: 225380.2,
        moneda: "MXN",
      },
      sku: "HFT9X930",
      p_envio: 0,
      etiquetas: ["Industria"],
      precio_az_sin_iva: {
        cantidad: 146551.72,
        moneda: "MXN",
      },
      imagen:
        "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/930_Mesadetrabajo1.jpg2024-03-20%2023%3A08%3A31.709265",
      href: "https://hantec.us/product/compresor-tornillo-forceelement-30hp-todo-en-uno-con-tanque,-secador-y-separador-de-part%C3%ADculas/109",
    },
  ];

  // Get data utm
  useEffect(() => {
    const extractUTMParameters = () => {
      const searchParams = new URLSearchParams(location.search);

      const utmSource = searchParams.get("utm_source");
      const utmMedium = searchParams.get("utm_medium") || "desconocido";
      const utmCampaign = searchParams.get("utm_campaign");
      const utmContent = searchParams.get("utm_content") || "desconocido";

      let today = new Date();
      today.setHours(0, 0, 0, 0);

      const lastVisitDateStr = window.localStorage.getItem("utm");
      const lastVisitDate = lastVisitDateStr
        ? new Date(JSON.parse(lastVisitDateStr).date)
        : null;
      lastVisitDate && lastVisitDate.setHours(0, 0, 0, 0);

      if (!lastVisitDate || dateDiffFromNow(today, lastVisitDate) > 2) {
        window.localStorage.removeItem("utm");
      }

      if (utmSource && utmMedium && utmCampaign) {
        const valuesUTM = {
          utmSource: utmSource,
          utmMedium: utmMedium,
          utmCampaign: utmCampaign,
          utmContent: utmContent,
          date: today,
        };
        window.localStorage.setItem("utm", JSON.stringify(valuesUTM));
        SnipTrafficPlatform(utmSource, utmCampaign, utmContent);
      }
    };

    extractUTMParameters();
  }, [location.search]);

  const handleOptionClick = (message) => {
    window.open(`https://wa.me/5593311600?text=${encodeURIComponent(message)}`);
    // setShowWhatsAppOptions(false);
    // setShowIcon(true);
  };

  const handleCallClick = () => {
    window.location.href = "tel:5593311600";
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: 0,
        left: -scrollRef.current.clientWidth / 2.5,
        behavior: "smooth",
      });
    }
    setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 2));
  };

  // Función para desplazar a la derecha
  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: 0,
        left: scrollRef.current.clientWidth / 2.5,
        behavior: "smooth",
      });
    }
    setCurrentIndex((prevIndex) =>
      Math.min(products.length - 2, prevIndex + 2)
    );
  };

  const relatedArticles = [
    {
      title: "Bienvenidos a Hantec",
      description: "Diferencia y Valor en Herramientas Automotrices...",
      image: imgBienvenidaMin,
      href: "/blog/autozone/1",
      buttonText: "Leer Más",
    },
    {
      title: "Alineadoras",
      description: "Optimiza tu taller con alineadoras de dirección...",
      image: imgAlineadoras,
      href: "/alineadoras/autozone",
      buttonText: "Leer Más",
    },
    {
      title: "Desmontadoras",
      description:
        "Explora las últimas innovaciones en el mundo de las herramientas...",
      image: imgDesmontadoras,
      href: "/desmontadoras/autozone",
      buttonText: "Leer Más",
    },
  ];

  return (
    <div className="bg-background">
      <Header />
      <main>
        <section className="relative h-[90vh] overflow-hidden">
          <img
            src={imgBackground}
            alt="Compresor Force Element"
            width="1600"
            height="800"
            className="h-full w-full object-cover object-center aspect-[1600/800]"
            style={{ aspectRatio: 1600 / 800, objectFit: "cover" }}
            // style="aspect-ratio: 1600 / 800; object-fit: cover;"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-background to-transparent"></div>
          <div className="absolute inset-0 flex justify-center pt-6 px-2">
            {/* // items-center pt-0*/}
            <div className="max-w-2xl text-center">
              <h1 className="text-4xl font-bold tracking-tight sm:text-6xl text-white ">
                Compresor de Tornillo
              </h1>
              <h2 className="text-2xl md:text-5xl font-bold tracking-tight text-white -mt-2">
                Force Element
              </h2>
              <p className="mt-4 text-xl text-muted-foreground text-white">
                Libera el poder del aire comprimido con una eficiencia y un
                rendimiento inigualables.
              </p>
              <div className="mt-4 flex justify-center gap-4">
                {/* <button
                  onClick={() =>
                    handleOptionClick(
                      "Ví este producto en el sitio web y estoy interesado https://hantec.us/landing_compressor"
                    )
                  }
                  className="text-[#0139b6] font-black bg-primary bg-white/60 hover:bg-white border-white inline-flex items-center justify-center whitespace-nowrap rounded-full text-lg ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
                >
                  Comprar ahora
                </button> */}
                {/* <a
                  href="https://hantec.us/Categorias/Compresores"
                  className="bg-[#facc15] text-[#1a2869] font-black hover:bg-[#facc15]/70 inline-flex items-center justify-center whitespace-nowrap rounded-full text-lg ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground hover:bg-secondary/80 h-10 px-4 py-2"
                >
                  Comprar Ahora
                </a> */}
              </div>
            </div>
          </div>
        </section>

        <section
          id="description_compresor"
          className="py-12 md:py-24 bg-muted bg-gray-100 mt-14"
        >
          <div className="container mx-auto px-4 md:px-6">
            <div className="space-y-4 mb-8">
              <h2 className="text-3xl font-bold">Compresor Tornillo 4 en 1</h2>
              <p className="text-muted-foreground">
                Los compresores de tornillo Hantec Force Element representan la
                cúspide de la ingeniería en compresión de aire, diseñados para
                satisfacer las demandas de las aplicaciones industriales más
                exigentes.
              </p>

              {/* <p className="text-muted-foreground pb-6">
                Con un enfoque en la eficiencia energética, la durabilidad y el
                control inteligente, estos compresores son la elección ideal
                para empresas que buscan maximizar su productividad mientras
                minimizan costos operativos y su impacto ambiental.
              </p>
              <p>
                Equipados con tecnologías avanzadas como motores de imán
                permanente y sistemas de variador de velocidad (VSD), los
                compresores Hantec están construidos para ofrecer un rendimiento
                constante y fiable, adaptándose a las necesidades cambiantes de
                cualquier operación industrial.
              </p> */}
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              <a
                className="block bg-background rounded-lg shadow-lg overflow-hidden transition-transform hover:-translate-y-1 hover:shadow-xl"
                href="#"
                rel="ugc"
              >
                <div></div>
              </a>
            </div>
          </div>
        </section>

        <section className="relative group flex flex-col justify-center items-center mt-6">
          <iframe
            className="w-4/5 aspect-video"
            src="https://www.youtube.com/embed/zxAzJqjXksc"
            title="Compresor Tornillo 4 en 1 de Hantec"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </section>

        <section id="features" className="py-12 md:py-24">
          <div className="container mx-auto px-4 md:px-6">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
              <div className="space-y-4">
                <div className="flex gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-8 w-14 md:w-8 text-primary text-[#1a2869]"
                  >
                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                    <circle cx="12" cy="12" r="4"></circle>
                  </svg>
                  <h3 className="text-2xl font-bold">
                    <span></span> Motor PMSM (Permanent Magnet Synchronous
                    Motor)
                  </h3>
                </div>
                <p className="text-muted-foreground">
                  Alta Eficiencia Energética: Nuestro compresor está equipado
                  con un Motor Síncrono de Imán Permanente (PMSM), que utiliza
                  imanes de neodimio de alta intensidad magnética. Estos imanes
                  permiten que el motor funcione con mayor eficiencia,
                  reduciendo significativamente las pérdidas de energía en
                  comparación con los motores tradicionales.
                </p>
              </div>
              <div className="space-y-4">
                <div className="flex gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // width="100"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-8 w-16 md:w-12 text-primary text-[#1a2869]"
                  >
                    <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
                  </svg>
                  <h3 className="text-2xl font-bold">
                    Ahorro Inteligente y Rendimiento Adaptativo Variador de
                    Velocidad (VSD)
                  </h3>
                </div>
                <p className="text-muted-foreground">
                  En un entorno industrial donde la optimización de recursos es
                  crucial para la competitividad, los compresores de tornillo
                  ForceElement se destacan al estar equipados con la avanzada
                  tecnología Variable Speed Drive (VSD).
                </p>
                <p>
                  Este variador de velocidad ajusta dinámicamente la velocidad
                  del motor para adaptarse a la demanda real de aire comprimido,
                  maximizando la eficiencia energética y reduciendo el costo
                  total de propiedad (TCO), una estrategia clave para cualquier
                  operación industrial.
                </p>
              </div>
              <div className="space-y-4">
                <div className="flex gap-1">
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-8 w-8 text-primary text-[#1a2869]"
                  >
                    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                    viewBox="0 0 512 512"
                    className="h-8 w-8 text-primary text-[#1a2869]"
                  >
                    <path
                      fill="#1a2869"
                      d="M505 442.7l-99.7-99.7c-4.5-4.5-10.6-7-17-7h-16.3c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6 .1-34zm-297-90.7c-79.5 0-144-64.3-144-144 0-79.5 64.4-144 144-144 79.5 0 144 64.3 144 144 0 79.5-64.4 144-144 144zm27.1-152.5l-45-13.5c-5.2-1.6-8.8-6.8-8.8-12.7 0-7.3 5.3-13.2 11.8-13.2h28.1c4.6 0 9 1.3 12.8 3.7 3.2 2 7.4 1.9 10.1-.7l11.8-11.2c3.5-3.4 3.3-9.2-.6-12.1-9.1-6.8-20.1-10.8-31.4-11.4V112c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v16.1c-23.6 .6-42.7 20.6-42.7 45.1 0 20 13 37.8 31.6 43.4l45 13.5c5.2 1.6 8.8 6.8 8.8 12.7 0 7.3-5.3 13.2-11.8 13.2h-28.1c-4.6 0-9-1.3-12.8-3.7-3.2-2-7.4-1.9-10.1 .7l-11.8 11.2c-3.5 3.4-3.3 9.2 .6 12.1 9.1 6.8 20.1 10.8 31.4 11.4V304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-16.1c23.6-.6 42.7-20.5 42.7-45.1 0-20-13-37.8-31.6-43.4z"
                    />
                  </svg>
                  <h3 className="text-2xl font-bold">
                    Ahorro en Costos Operativos
                  </h3>
                </div>
                <p className="text-muted-foreground">
                  Gracias a la tecnología VSD (Variable Speed Drive), el motor
                  ajusta su velocidad de acuerdo a la demanda de trabajo, lo que
                  permite un ahorro de hasta un 60% en costos operativos y hasta
                  un 20% en el consumo de energía.
                </p>
                <p>
                  Esto no solo reduce tus costos de operación, sino que también
                  prolonga la vida útil del equipo, asegurando que tu inversión
                  esté protegida por más de 15 años.
                </p>
              </div>
              <div className="space-y-4">
                <div className="flex gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-8 w-8 text-primary text-[#1a2869]"
                  >
                    <path d="M14 4v10.54a4 4 0 1 1-4 0V4a2 2 0 0 1 4 0Z"></path>
                  </svg>
                  <h3 className="text-2xl font-bold">
                    Capacidad de Temperatura Extrema
                  </h3>
                </div>
                <p className="text-muted-foreground">
                  Diseñado para operar en entornos de alta temperatura de hasta
                  50°C, el Compresor Force Element es adaptable a una amplia
                  gama de aplicaciones industriales y comerciales, asegurando un
                  rendimiento confiable incluso en las condiciones más
                  exigentes.
                </p>
              </div>
              <div className="space-y-4">
                <div className="flex gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-8 w-8 text-primary text-[#1a2869]"
                  >
                    <rect width="20" height="16" x="2" y="4" rx="2"></rect>
                    <path d="M2 14h20"></path>
                    <path d="M12 20v-6"></path>
                  </svg>
                  <h3 className="text-2xl font-bold">Control Intuitivo</h3>
                </div>
                <p className="text-muted-foreground">
                  El Compresor Force Element cuenta con una interfaz de pantalla
                  táctil a color de fácil uso, lo que le permite monitorear y
                  controlar fácilmente la operación del compresor, asegurando un
                  rendimiento y eficiencia óptimos.
                </p>
              </div>
              <div className="space-y-4">
                <div className="flex gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-8 w-8 text-primary text-[#1a2869]"
                  >
                    <path d="M16 22h2a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v18"></path>
                    <path d="M14 2v4a2 2 0 0 0 2 2h4"></path>
                    <circle cx="10" cy="20" r="2"></circle>
                    <path d="M10 7V6"></path>
                    <path d="M10 12v-1"></path>
                    <path d="M10 18v-2"></path>
                  </svg>
                  <h3 className="text-2xl font-bold">Diseño Todo en Uno</h3>
                </div>
                <p className="text-muted-foreground">
                  El Compresor Force Element integra el compresor, el tanque, el
                  secador y el filtro en una sola unidad compacta, simplificando
                  la instalación y reduciendo el espacio total requerido en su
                  espacio de trabajo.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="benefits" className="py-12 md:py-24 bg-muted bg-gray-100">
          <div className="container mx-auto px-4 md:px-6">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              <div className="space-y-4">
                <h2 className="text-3xl font-bold">Beneficios Inigualables</h2>
                <p className="text-muted-foreground">
                  El Compresor Force Element ofrece un rendimiento y un valor
                  excepcionales para su negocio.
                </p>
                <div className="flex justify-center">
                  <img
                    src={imgCompresor}
                    alt="pieza de compresor"
                    className="w-auto h-80"
                  />
                </div>
              </div>
              <div className="space-y-4">
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary"
                  >
                    <path d="M20 6 9 17l-5-5"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Ahorro de Energía</h3>
                    <p className="text-muted-foreground">
                      Reduzca sus costos de energía en hasta un 60% con la
                      eficiente tecnología PMSM y VSD.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary"
                  >
                    <path d="M20 6 9 17l-5-5"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Rendimiento Confiable</h3>
                    <p className="text-muted-foreground">
                      Disfrute de un funcionamiento duradero y confiable con la
                      vida útil de 15 años o más del Compresor Force Element.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary"
                  >
                    <path d="M20 6 9 17l-5-5"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">
                      Espacio de Trabajo Silencioso
                    </h3>
                    <p className="text-muted-foreground">
                      Cree un ambiente de trabajo tranquilo con niveles de ruido
                      tan bajos como 63±dB(A).
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary"
                  >
                    <path d="M20 6 9 17l-5-5"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">
                      Aplicaciones Versátiles
                    </h3>
                    <p className="text-muted-foreground">
                      Adecuado para una amplia gama de aplicaciones industriales
                      y comerciales, incluso en entornos de alta temperatura de
                      hasta 50°C.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary"
                  >
                    <path d="M20 6 9 17l-5-5"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Control Intuitivo</h3>
                    <p className="text-muted-foreground">
                      Monitoree y controle fácilmente la operación del compresor
                      con la interfaz de pantalla táctil a color de fácil uso.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary"
                  >
                    <path d="M20 6 9 17l-5-5"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Diseño Compacto</h3>
                    <p className="text-muted-foreground">
                      El diseño todo en uno integra el compresor, el tanque, el
                      secador y el filtro en una sola unidad que ahorra espacio.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="sistema_compresion_aire"
          className="py-12 md:py-24 bg-muted"
        >
          <div className="container mx-auto px-4 md:px-6">
            <div className="space-y-4 mb-8">
              <h2 className="text-3xl font-bold">
                Sistema de Compresión de Aire
              </h2>
              <p className="font-semibold">Compresión sin Pulsaciones:</p>
              <p className="text-muted-foreground">
                El diseño avanzado del sistema de compresión utiliza un par de
                rotores que giran en direcciones opuestas, atrapando y
                comprimiendo el aire de manera eficiente y constante. Este flujo
                constante sin pulsaciones es ideal para aplicaciones que
                requieren un suministro continuo y estable de aire comprimido,
                mejorando la precisión y calidad de los procesos.
              </p>
              <p className="font-semibold">Tanque Separador de Aceite:</p>
              <p className="text-muted-foreground">
                El compresor cuenta con un tanque separador de aceite integrado
                en la parte frontal del equipo, asegurando que el aceite se
                separe eficazmente del aire comprimido. Esto no solo garantiza
                un aire limpio, sino que también facilita el mantenimiento al
                incorporar un medidor de nivel de aceite y un compartimento de
                drenaje y llenado de fácil acceso.
              </p>
              <div className="flex justify-center">
                <img
                  src={imgSistemaCompresionAire}
                  alt="pieza de compresor"
                  className="w-auto h-80"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              <a
                className="block bg-background rounded-lg shadow-lg overflow-hidden transition-transform hover:-translate-y-1 hover:shadow-xl"
                href="#"
                rel="ugc"
              >
                <div></div>
              </a>
            </div>
          </div>
        </section>

        <section
          id="filtros_ats"
          className="py-12 md:py-24 bg-muted bg-gray-100 mt-16"
        >
          <div className="container mx-auto px-4 md:px-6">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              <div className="space-y-4">
                <h2 className="text-3xl font-bold">
                  Filtros ATS de Alta Calidad
                </h2>
                {/* <p className="text-muted-foreground">
                  El Compresor Force Element ofrece un rendimiento y un valor
                  excepcionales para su negocio.
                </p> */}
                <div>
                  <p className="pb-5">
                    Los filtros ATS en el compresor Tornillo 4 en 1 tienen
                    funciones específicas para garantizar la calidad del aire
                    comprimido.
                  </p>
                  <p className="pb-5">
                    Estos filtros se encargan de eliminar las impurezas y
                    contaminantes del aire, como partículas sólidas, humedad y
                    aceite, asegurando que el aire comprimido cumpla con los
                    estándares de calidad necesarios para su uso en diversas
                    aplicaciones industriales.
                  </p>
                  <p className="pb-5">
                    De esta manera, los filtros ATS ayudan a mantener el
                    rendimiento óptimo del compresor y a proteger los equipos
                    que utilizan el aire comprimido, prolongando su vida útil y
                    reduciendo el riesgo de fallos o problemas operativos
                  </p>
                </div>
              </div>
              <div className="space-y-4">
                <div className="flex justify-center items-center">
                  <img
                    src={imgFiltroAts}
                    alt="pieza de compresor"
                    className="w-auto h-full"
                  />
                </div>
                {/* <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary"
                  >
                    <path d="M20 6 9 17l-5-5"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Ahorro de Energía</h3>
                    <p className="text-muted-foreground">
                      Reduzca sus costos de energía en hasta un 60% con la
                      eficiente tecnología PMSM y VSD.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary"
                  >
                    <path d="M20 6 9 17l-5-5"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Rendimiento Confiable</h3>
                    <p className="text-muted-foreground">
                      Disfrute de un funcionamiento duradero y confiable con la
                      vida útil de 15 años o más del Compresor Force Element.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary"
                  >
                    <path d="M20 6 9 17l-5-5"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">
                      Espacio de Trabajo Silencioso
                    </h3>
                    <p className="text-muted-foreground">
                      Cree un ambiente de trabajo tranquilo con niveles de ruido
                      tan bajos como 63±dB(A).
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary"
                  >
                    <path d="M20 6 9 17l-5-5"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">
                      Aplicaciones Versátiles
                    </h3>
                    <p className="text-muted-foreground">
                      Adecuado para una amplia gama de aplicaciones industriales
                      y comerciales, incluso en entornos de alta temperatura de
                      hasta 50°C.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary"
                  >
                    <path d="M20 6 9 17l-5-5"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Control Intuitivo</h3>
                    <p className="text-muted-foreground">
                      Monitoree y controle fácilmente la operación del compresor
                      con la interfaz de pantalla táctil a color de fácil uso.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary"
                  >
                    <path d="M20 6 9 17l-5-5"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Diseño Compacto</h3>
                    <p className="text-muted-foreground">
                      El diseño todo en uno integra el compresor, el tanque, el
                      secador y el filtro en una sola unidad que ahorra espacio.
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <section id="capacidad_tanque" className="py-12 md:py-24 bg-muted">
          <div className="container mx-auto px-4 md:px-6">
            <div className="space-y-4 mb-8">
              <h2 className="text-3xl font-bold">Capacidad del tanque</h2>
              <p className="font-semibold">Opciones Flexibles:</p>
              <p className="text-muted-foreground">
                Entendemos que cada taller tiene necesidades diferentes. Es por
                eso que ofrecemos el Compresor Tornillo 4 en 1 en versiones con
                tanques de{" "}
                <span className="font-semibold">250 a 400 litros</span>, lo que
                permite adaptarse a diferentes volúmenes de trabajo y garantizar
                un suministro continuo de aire comprimido, incluso durante
                largas jornadas.
              </p>
              <p className="text-muted-foreground">
                Los compresores tornillo 2 en 1 y 3 en1 tienen una capacidad de
                100L y 130L.
              </p>
              <div className="flex justify-center">
                <img
                  src={imgTanqueCompresor}
                  alt="imagen de un compresor"
                  className="w-80 h-auto" //h-80
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              <a
                className="block bg-background rounded-lg shadow-lg overflow-hidden transition-transform hover:-translate-y-1 hover:shadow-xl"
                href="#"
                rel="ugc"
              >
                <div></div>
              </a>
            </div>
          </div>
        </section>

        <section id="normas" className="py-12 md:py-24 bg-muted">
          <div className="container mx-auto px-4 md:px-6">
            <div className="space-y-4 mb-8">
              <h2 className="text-3xl font-bold">
                Cumplimiento Normativo y Rendimiento Superior
              </h2>
              <p>
                En Hantec, nos comprometemos a ofrecer productos que no solo
                cumplen con los más altos estándares de calidad, sino que
                también están diseñados para superar las expectativas de
                nuestros clientes.
              </p>
              <p className="font-semibold">Conformidad con la Norma 7183:</p>
              <p className="text-muted-foreground">
                Nuestros compresores cumplen con la Norma 7183, que especifica
                un rango de presión de aire entre 5 y 16 bares. Este
                cumplimiento asegura que nuestros compresores no solo son
                eficientes, sino también seguros y confiables en una amplia gama
                de aplicaciones.
              </p>
              <p className="font-semibold">
                Conformidad con la Norma ISO 8573:
              </p>
              <p className="text-muted-foreground">
                Nuestros compresores 4 EN 1 cumplen con la Norma ISO 8573, que
                regula la calidad del aire comprimido, asegurando niveles
                máximos admitidos de partículas, humedad y aceite. Esto es
                especialmente crucial en aplicaciones que requieren aire de alta
                pureza, como en la industria alimentaria, farmacéutica, y la
                pintura electrostática.
              </p>
              <div className="flex justify-center">
                <img
                  src={imgCertificados}
                  alt="imagen de los certificados del compresor"
                  className="w-full h-auto" //h-80
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              <a
                className="block bg-background rounded-lg shadow-lg overflow-hidden transition-transform hover:-translate-y-1 hover:shadow-xl"
                href="#"
                rel="ugc"
              >
                <div></div>
              </a>
            </div>
          </div>
        </section>

        {/* Table 1 */}
        <TblTechnicalSpecifications products={products} />

        {/* Especificaciones tecnicas */}
        <section id="specs" className="py-12 md:py-24">
          <div className="container mx-auto px-4 md:px-6">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              <div>
                <h2 className="text-3xl font-bold">
                  Especificaciones Técnicas
                </h2>
                <p className="mt-4 text-muted-foreground">
                  Descubra las características avanzadas que hacen del Compresor
                  Force Element una opción destacada.
                </p>
              </div>
              <div className="space-y-4">
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary text-[#1a2869]"
                  >
                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                    <circle cx="12" cy="12" r="4"></circle>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Tipo de Motor</h3>
                    <p className="text-muted-foreground">
                      Motor Síncrono de Imán Permanente (PMSM)
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary text-[#1a2869]"
                  >
                    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Nivel de Ruido</h3>
                    <p className="text-muted-foreground">63±dB(A)</p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary text-[#1a2869]"
                  >
                    <path d="M14 4v10.54a4 4 0 1 1-4 0V4a2 2 0 0 1 4 0Z"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">
                      Temperatura de Operación
                    </h3>
                    <p className="text-muted-foreground">Hasta 50°C</p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary text-[#1a2869]"
                  >
                    <path d="m12 14 4-4"></path>
                    <path d="M3.34 19a10 10 0 1 1 17.32 0"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Rango de Presión</h3>
                    <p className="text-muted-foreground">7-15 bar</p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary text-[#1a2869]"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="m4.93 4.93 4.24 4.24"></path>
                    <path d="m14.83 9.17 4.24-4.24"></path>
                    <path d="m14.83 14.83 4.24 4.24"></path>
                    <path d="m9.17 14.83-4.24 4.24"></path>
                    <circle cx="12" cy="12" r="4"></circle>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Vida Útil</h3>
                    <p className="text-muted-foreground">Hasta 15 años</p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary text-[#1a2869]"
                  >
                    <rect width="20" height="16" x="2" y="4" rx="2"></rect>
                    <path d="M2 14h20"></path>
                    <path d="M12 20v-6"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">Interfaz de Control</h3>
                    <p className="text-muted-foreground">
                      Pantalla Táctil a Color
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mt-1 h-6 w-6 flex-shrink-0 text-primary text-[#1a2869]"
                  >
                    <path d="M16 22h2a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v18"></path>
                    <path d="M14 2v4a2 2 0 0 0 2 2h4"></path>
                    <circle cx="10" cy="20" r="2"></circle>
                    <path d="M10 7V6"></path>
                    <path d="M10 12v-1"></path>
                    <path d="M10 18v-2"></path>
                  </svg>
                  <div>
                    <h3 className="text-xl font-bold">
                      Componentes Integrados
                    </h3>
                    <p className="text-muted-foreground">
                      Compresor, Tanque, Secador, Filtro
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="applications"
          className="py-12 md:py-24 bg-muted bg-gray-100"
        >
          <div className="container mx-auto px-4 md:px-6">
            <div className="space-y-4 mb-8">
              <h2 className="text-3xl font-bold">Aplicaciones Industriales</h2>
              <p className="text-muted-foreground">
                El Compresor Force Element está diseñado para satisfacer las
                necesidades de una amplia gama de industrias y aplicaciones.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              <a
                className="block bg-background rounded-lg shadow-lg overflow-hidden transition-transform hover:-translate-y-1 hover:shadow-xl"
                href="#"
                rel="ugc"
              >
                <div></div>
              </a>
            </div>
          </div>
        </section>

        {/* Table 2*/}
        <section className="relative py-12 md:py-24">
          <div className="container mx-auto px-4 md:px-6">
            <div className="space-y-4 mb-8">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <div className="grid gap-2 pb-2 md:pb-0">
                  <h2 className="text-3xl font-bold">
                    Comparación de Características
                  </h2>
                  <p className="text-muted-foreground">
                    Descubre cómo los Compresores ForceElement se comparan entre
                    sí.
                  </p>
                </div>
                {/* <a
                  href="https://hantec.us/Categorias/Compresores"
                  className="bg-[#facc15] text-[#1a2869] inline-flex items-center justify-center whitespace-nowrap text-sm font-bold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-9 rounded-md px-3"
                >
                  Comprar ahora
                </a> */}
              </div>
              {/* <div className="overflow-x-scroll flex md:grid md:grid-cols-5 gap-4">                
                {products?.map((product) => {
                  return <ProductCard key={product?.id_producto} product={product} />;
                })}
              </div> */}

              {/* {!isMobile && (
                <div className="md:grid md:grid-cols-5 gap-4">
                  {products?.map((product) => (
                    <ProductCard key={product?.id_producto} product={product} />
                  ))}
                </div>
              )} */}

              {/* {isMobile && (
                <div className="relative overflow-hidden">
                  <div
                    className="flex transition-transform duration-300 ease-in-out"
                    style={{ transform: `translateX(-${currentIndex * 50}%)` }}
                  >
                    {products?.map((product) => (
                      <div
                        key={product?.id_producto}
                        className="w-1/2 flex-shrink-0"
                      >
                        <ProductCard product={product} />
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={handlePrev}
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full transition-opacity duration-300 ease-in-out"
                    disabled={currentIndex === 0}
                    style={{ opacity: currentIndex === 0 ? 0.5 : 1 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="25"
                      width="25"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8 .4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z" />
                    </svg>
                  </button>
                  <button
                    onClick={handleNext}
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full transition-opacity duration-300 ease-in-out"
                    disabled={currentIndex >= products.length - 2}
                    style={{
                      opacity: currentIndex >= products.length - 2 ? 0.5 : 1,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="25"
                      width="25"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z" />
                    </svg>
                  </button>
                </div>
              )} */}
            </div>

            <div ref={scrollRef} className="overflow-x-auto">
              <table className="w-full text-left">
                <thead>
                  <tr className="bg-muted bg-muted bg-gray-100">
                    <th className="sticky left-0 h-fit bg-gray-100 px-4 py-3 font-bold">
                      Características
                    </th>
                    <th className="px-4 py-3 font-bold">
                      ForceElement HFT9X927
                    </th>
                    <th className="px-4 py-3 font-bold">
                      ForceElement HFT9X937
                    </th>
                    <th className="px-4 py-3 font-bold">
                      ForceElement HFT9X910
                    </th>
                    <th className="px-4 py-3 font-bold">
                      ForceElement HFT9X920
                    </th>
                    <th className="px-4 py-3 font-bold">
                      ForceElement HFT9X930
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium"></td>
                    <td className="px-4 py-3">
                      <ProductCard product={products[0]} />
                    </td>
                    <td className="px-4 py-3">
                      <ProductCard product={products[1]} />
                    </td>
                    <td className="px-4 py-3">
                      <ProductCard product={products[2]} />
                    </td>
                    <td className="px-4 py-3">
                      <ProductCard product={products[3]} />
                    </td>
                    <td className="px-4 py-3">
                      <ProductCard product={products[4]} />
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                      Compresor
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                      Tanque
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                      Secador
                    </td>
                    <td className="px-4 py-3">-</td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                      Separador de Partículas
                    </td>
                    <td className="px-4 py-3">-</td>
                    <td className="px-4 py-3">-</td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                    <td className="px-4 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-5 h-5 fill-primary"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                onClick={scrollLeft}
                className="md:hidden absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full transition-opacity duration-300 ease-in-out"
                // disabled={currentIndex === 0}
                style={{ opacity: currentIndex === 0 ? 0.5 : 1 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  width="25"
                  viewBox="0 0 320 512"
                >
                  <path d="M34.5 239L228.9 44.7c9.4-9.4 24.6-9.4 33.9 0l22.7 22.7c9.4 9.4 9.4 24.5 0 33.9L131.5 256l154 154.8c9.3 9.4 9.3 24.5 0 33.9l-22.7 22.7c-9.4 9.4-24.6 9.4-33.9 0L34.5 273c-9.4-9.4-9.4-24.6 0-33.9z" />
                </svg>
              </button>
              <button
                // onClick={handleNext}
                onClick={scrollRight}
                className="md:hidden absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full transition-opacity duration-300 ease-in-out"
                // disabled={currentIndex >= products.length - 2}
                style={{
                  opacity: currentIndex >= products.length - 2 ? 0.5 : 1,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  width="25"
                  viewBox="0 0 320 512"
                >
                  <path d="M285.5 273L91.1 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.7-22.7c-9.4-9.4-9.4-24.5 0-33.9L188.5 256 34.5 101.3c-9.3-9.4-9.3-24.5 0-33.9l22.7-22.7c9.4-9.4 24.6-9.4 33.9 0L285.5 239c9.4 9.4 9.4 24.6 0 33.9z" />
                </svg>
              </button>
            </div>
          </div>
        </section>
      </main>

      {/* Call To Action */}    
      <CallToAction
        title={"¡Mantén una actitud positiva, sé persistente, y lograrás grandes cosas!"}
        subtitle={"Descubre cómo las herramientas Hantec pueden ayudarte a alcanzar el éxito en ventas."}
        className={"max-w-4xl mx-auto px-4 py-8 space-y-8"}
      />

      <RelatedArticles 
      className={"max-w-4xl mx-auto px-4 py-8"}
      relatedArticles={relatedArticles}
      />

      <Footer />
    </div>
  );
}

export default LandingCompressor;
