import "../../assets/style/desmontadora_style.css";

import img1Des from "../../assets/products/desmontadoras/des_1w.webp";
import img2Des from "../../assets/products/desmontadoras/des_2w.webp";
import img3Des from "../../assets/products/desmontadoras/des_3w.webp";

import icon1Des from "../../assets/products/desmontadoras/miniatura_desmo_1.webp";
import icon2Des from "../../assets/products/desmontadoras/miniatura_desmo_2.webp";
import icon3Des from "../../assets/products/desmontadoras/miniatura_desmo_3.webp";
import icon4Des from "../../assets/products/desmontadoras/miniatura_desmo_4.webp";
import icon5Des from "../../assets/products/desmontadoras/miniatura_desmo_5.webp";
import icon6Des from "../../assets/products/desmontadoras/miniatura_desmo_6.webp";

import imgCompressor from "../../assets/bg-compressor.webp";
import imgAlineadoras from "../../assets/products/alineadoras/test_ali2.webp";
import imgBienvenidaMin from "../../assets/blog/bienvenida_min.webp";

import bgMain from "../../assets/products/desmontadoras/bg_main.webp";
import Footer from "../../componentes/footer/Footer";
import Header from "../../componentes/Headers/Header";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RelatedArticles from "../../componentes/blog/RelatedArticles";
import CallToAction from "../../componentes/blog/CallToAction";

function LandingDesmontadora() {
  const images = [img1Des, img2Des, img3Des];
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexVideo, setCurrentIndexVideo] = useState(0);

  const imagesYT = [
    "https://www.youtube.com/embed/SjGQfn02VHk",
    "https://www.youtube.com/embed/vkm_vvwwuNI",
    "https://www.youtube.com/embed/aED9-aKMPe0",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlideVideo = () => {
    setCurrentIndexVideo((prevIndex) =>
      prevIndex === imagesYT.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlideVideo = () => {
    setCurrentIndexVideo((prevIndex) =>
      prevIndex === 0 ? imagesYT.length - 1 : prevIndex - 1
    );
  };

  const mainProducts = [
    {
      HTCD908PB: {
        sku: "HTCD908PB",
        image:
          "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/CARRUSEL_1.png2024-08-16%2015%3A25%3A45.420172",
        url: "https://hantec.us/product/desmontadora-de-llantas-13-a-28-pulgadas-hantec-con-turboinflado-y-brazo/6",
      },
      HTCD908P: {
        sku: "HTCD908P",
        image:
          "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/1_fd5e049c-3141-4469-903b-97e7f4a917b8.jpg2024-03-28%2021%3A12%3A09.429056",
        url: "https://hantec.us/product/desmontadora-de-llantas-13-a-28-pulgadas-hantec-con-turboinflado/5",
      },
      HTCD808B: {
        sku: "HTCD808B",
        image:
          "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/1_90d157fe-54d4-40f6-a493-a37575467e0e.jpg2024-03-28%2019%3A57%3A07.800486",
        url: "https://hantec.us/product/desmontadora-de-llantas-10-a-24-pulgadas-con-brazo-hantec/4",
      },
      HTCD808: {
        sku: "HTCD808",
        image:
          "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/1_ca0c11fd-009e-4183-b50e-658d8a51a0e8.jpg2024-03-28%2018%3A55%3A34.959159",
        url: "https://hantec.us/product/desmontadora-de-llantas-11-a-24-pulgadas-hantec/3",
      },
    },
  ];

  const relatedArticles = [
    {
      title: "Bienvenidos a Hantec",
      description: "Diferencia y Valor en Herramientas Automotrices...",
      image: imgBienvenidaMin,
      href: "/blog/autozone/1",
      buttonText: "Leer Más",
    },
    {
      title: "Alineadoras",
      description: "Optimiza tu taller con alineadoras de dirección...",
      image: imgAlineadoras,
      href: "/alineadoras/autozone",
      buttonText: "Leer Más",
    },
    {
      title: "Compresores",
      description:
        "Libera el poder del aire comprimido con una eficiencia y un rendimiento inigualables...",
      image: imgCompressor,
      href: "/force_element",
      buttonText: "Leer Más",
    },
  ];

  return (
    <>
      <Header />
      <div className=" text-foreground  ">
        <section className="relative h-[90vh] overflow-hidden">
          <div
            className="absolute inset-0 bg-cover bg-center "
            style={{ backgroundImage: bgMain }}
          >
            <div className="absolute inset-0">
              <img
                src={bgMain}
                alt="banner desmontadoras"
                width="1600"
                height="800"
                className="h-full w-full object-cover object-center aspect-[1600/800]"
                style={{ aspectRatio: 1600 / 800, objectFit: "cover" }}
              />
            </div>

            <div className="absolute inset-0 flex justify-center pt-6 px-2">
              <div className="max-w-2xl text-center">
                <h1 className="font-montserrat text-4xl font-medium tracking-tight sm:text-6xl text-white ">
                  Desmontadora de Neumáticos HANTEC
                </h1>
                {/* <h2 className="text-2xl md:text-5xl font-bold tracking-tight text-black -mt-2">Force Element</h2> */}
                <p className="font-montserrat mt-4 text-xl text-muted-foreground text-white">
                  Maximiza la eficiencia y la precisión con las desmontadoras de
                  neumáticos
                </p>
                <div className="mt-4 flex justify-center gap-4">
                  {/* <a
                href="https://hantec.us/Categorias/Compresores"
                className="bg-[#facc15] text-[#1a2869] font-black hover:bg-[#facc15]/70 inline-flex items-center justify-center whitespace-nowrap rounded-full text-lg ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground hover:bg-secondary/80 h-10 px-4 py-2"
            >
                Más Información
            </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Video Presentation */}
        <section className="py-16 bg-gray-100 overflow-hidden">
          <div className="container mx-auto px-4">
            {/* Carousel de videos */}
            <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
              <div
                className="relative w-full"
                style={{ paddingBottom: "56.25%" }}
              >
                {imagesYT.map((image, index) => (
                  <div
                    key={index}
                    className={`absolute inset-0 w-full h-full transition-all duration-500 ease-in-out transform ${
                      index === currentIndexVideo
                        ? "translate-x-0 opacity-100"
                        : index < currentIndexVideo
                        ? "-translate-x-full opacity-0"
                        : "translate-x-full opacity-0"
                    }`}
                  >
                    <iframe
                      src={image}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-full h-full rounded-lg shadow-lg"
                    ></iframe>
                  </div>
                ))}
                <button
                  onClick={prevSlideVideo}
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 transition-opacity duration-300 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  aria-label="Previous image"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="14"
                    width="12.25"
                    viewBox="0 0 448 512"
                  >
                    <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8 .4 34.3z" />
                  </svg>
                </button>
                <button
                  onClick={nextSlideVideo}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 transition-opacity duration-300 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  aria-label="Next image"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="14"
                    width="12.25"
                    viewBox="0 0 448 512"
                  >
                    <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="container mx-auto py-8 md:py-12 lg:py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="relative">
              {/* Carrusel */}

              <div className="relative w-full h-full overflow-hidden">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className={`absolute w-full h-full transition-all duration-500 ease-in-out transform ${
                      index === currentIndex
                        ? "translate-x-0 opacity-100"
                        : index < currentIndex
                        ? "-translate-x-full opacity-0"
                        : "translate-x-full opacity-0"
                    }`}
                  >
                    <img
                      src={image}
                      alt={`Slide ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
                <button
                  onClick={prevSlide}
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 transition-opacity duration-300 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  aria-label="Previous image"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="14"
                    width="12.25"
                    viewBox="0 0 448 512"
                  >
                    <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8 .4 34.3z" />
                  </svg>
                  {/* <ChevronLeft className="w-6 h-6 text-gray-800" /> */}
                </button>
                <button
                  onClick={nextSlide}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 transition-opacity duration-300 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  aria-label="Next image"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="14"
                    width="12.25"
                    viewBox="0 0 448 512"
                  >
                    <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" />
                  </svg>
                  {/* <ChevronRight className="w-6 h-6 text-gray-800" /> */}
                </button>
              </div>
            </div>
            <div className="flex flex-col justify-center gap-8 w-full px-6">
              <h2 className="font-montserrat text-3xl font-bold">
                Especificaciones Técnicas
              </h2>
              <ul className="space-y-6 text-lg text-muted-foreground">
                <li className="flex items-center gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-24 h-12 text-primary"
                  >
                    <path d="M21.3 15.3a2.4 2.4 0 0 1 0 3.4l-2.6 2.6a2.4 2.4 0 0 1-3.4 0L2.7 8.7a2.41 2.41 0 0 1 0-3.4l2.6-2.6a2.41 2.41 0 0 1 3.4 0Z"></path>
                    <path d="m14.5 12.5 2-2"></path>
                    <path d="m11.5 9.5 2-2"></path>
                    <path d="m8.5 6.5 2-2"></path>
                    <path d="m17.5 15.5 2-2"></path>
                  </svg>
                  <span className="font-montserrat text-justify">
                    13-28 pulgadas: El rango de sujeción del rin más avanzado en
                    nuestra gama, diseñado para ofrecer precisión y estabilidad
                    excepcionales.
                  </span>
                </li>
                <li className="flex items-center gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-16 h-8 text-primary"
                  >
                    <path d="M8 3H5a2 2 0 0 0-2 2v3"></path>
                    <path d="M21 8V5a2 2 0 0 0-2-2h-3"></path>
                    <path d="M3 16v3a2 2 0 0 0 2 2h3"></path>
                    <path d="M16 21h3a2 2 0 0 0 2-2v-3"></path>
                  </svg>
                  <span className="font-montserrat text-justify">
                    37 pulgadas: Diámetro máximo de la llanta, ideal para
                    vehículos de alto rendimiento.
                  </span>
                </li>
                <li className="flex items-center gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-24 h-8 text-primary"
                  >
                    <path d="m12 14 4-4"></path>
                    <path d="M3.34 19a10 10 0 1 1 17.32 0"></path>
                  </svg>
                  <span className="font-montserrat text-justify">
                    116-145 PSI: Presión de operación superior, garantizando
                    eficiencia en cualquier operación de desmontaje.
                  </span>
                </li>
                <li className="flex items-center gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-24 h-8 text-primary"
                  >
                    <path d="M12 22v-5"></path>
                    <path d="M9 8V2"></path>
                    <path d="M15 8V2"></path>
                    <path d="M18 8v5a4 4 0 0 1-4 4h-4a4 4 0 0 1-4-4V8Z"></path>
                  </svg>
                  <span className="font-montserrat text-justify">
                    110V, 60Hz: Fuente de alimentación estándar optimizada para
                    un rendimiento consistente y confiable.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="py-8">
          <h2 className="font-montserrat text-3xl font-bold mb-8 text-center">
            ¿Cuál es la mejor para tu taller?
          </h2>

          <div className="overflow-x-auto md:mx-12">
            <table className="w-full table-auto border-collapse">
              <thead>
                <tr className="bg-muted">
                  <th className="px-4 py-2 font-medium"></th>
                  <th className="px-4 py-2 font-medium">
                    <div className="flex flex-col items-center gap-2">
                      <img
                        src={mainProducts[0].HTCD908PB.image}
                        alt="HTCD908PB"
                        width={200}
                        height={200}
                        className="rounded-md"
                        style={{ aspectRatio: "80/80", objectFit: "cover" }}
                      />
                      <h3 className="text-lg font-medium">
                        Turbo Inflado con Brazo (HTCD908PB)
                      </h3>
                      <div className="flex gap-2">
                        {/* <a
                    href="#"
                    className="inline-flex h-8 items-center justify-center rounded-md bg-primary px-4 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                    prefetch={false}
                  >
                    Más Información
                  </a> */}
                        {/* <a
                          href="#"
                          className="bg-[#ffcc02cc] text-[#1e3d8be6] inline-flex h-8 items-center justify-center rounded-md border border-input bg-background px-4 text-sm font-bold shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                          prefetch={false}
                        >
                          Comprar
                        </a> */}
                      </div>
                    </div>
                  </th>
                  <th className="px-4 py-2 font-medium">
                    <div className="flex flex-col items-center gap-2">
                      <img
                        src={mainProducts[0].HTCD908P.image}
                        alt="HTCD908P"
                        width={200}
                        height={200}
                        className="rounded-md"
                        style={{ aspectRatio: "80/80", objectFit: "cover" }}
                      />
                      <h3 className="text-lg font-medium">
                        Turbo Inflado (HTCD908P)
                      </h3>
                      <div className="flex gap-2">
                        {/* <a
                    href="#"
                    className="inline-flex h-8 items-center justify-center rounded-md bg-primary px-4 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                    prefetch={false}
                  >
                    Más Información
                  </a> */}
                        {/* <a
                          href="#"
                          className="bg-[#ffcc02cc] text-[#1e3d8be6] inline-flex h-8 items-center justify-center rounded-md border border-input bg-background px-4 text-sm font-bold shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                          prefetch={false}
                        >
                          Comprar
                        </a> */}
                      </div>
                    </div>
                  </th>
                  <th className="px-4 py-2 font-medium">
                    <div className="flex flex-col items-center gap-2">
                      <img
                        src={mainProducts[0].HTCD808B.image}
                        alt="HTCD808B"
                        width={200}
                        height={200}
                        className="rounded-md"
                        style={{ aspectRatio: "80/80", objectFit: "cover" }}
                      />
                      <h3 className="text-lg font-medium">
                        Clásica con Brazo (HTCD808B)
                      </h3>
                      <div className="flex gap-2">
                        {/* <a
                    href="#"
                    className="inline-flex h-8 items-center justify-center rounded-md bg-primary px-4 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                    prefetch={false}
                  >
                    Más Información
                  </a> */}
                        {/* <a
                          href="#"
                          className="bg-[#ffcc02cc] text-[#1e3d8be6] inline-flex h-8 items-center justify-center rounded-md border border-input bg-background px-4 text-sm font-bold shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                          prefetch={false}
                        >
                          Comprar
                        </a> */}
                      </div>
                    </div>
                  </th>
                  <th className="px-4 py-2 font-medium">
                    <div className="flex flex-col items-center gap-2">
                      <img
                        src={mainProducts[0].HTCD808.image}
                        alt="HTCD808"
                        width={200}
                        height={200}
                        className="rounded-md"
                        style={{ aspectRatio: "80/80", objectFit: "cover" }}
                      />
                      <h3 className="text-lg font-medium">Clásica (HTCD808)</h3>
                      <div className="flex gap-2">
                        {/* <a
                    href="#"
                    className="inline-flex h-8 items-center justify-center rounded-md bg-primary px-4 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                    prefetch={false}
                  >
                    Más Información
                  </a> */}
                        {/* <a
                          href="#"
                          className="bg-[#ffcc02cc] text-[#1e3d8be6] inline-flex h-8 items-center justify-center rounded-md border border-input bg-background px-4 text-sm font-bold shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                          prefetch={false}
                        >
                          Comprar
                        </a> */}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b">
                  <td className="px-4 py-2 font-medium">
                    Rango de sujeción del rin
                  </td>
                  <td className="px-4 py-2">
                    24-11" (interna), 28-13" (externa)
                  </td>
                  <td className="px-4 py-2">
                    24-11" (interna), 28-13" (externa)
                  </td>
                  <td className="px-4 py-2">
                    24-12" (interna), 22-10" (externa)
                  </td>
                  <td className="px-4 py-2">
                    24-12" (interna), 22-10" (externa)
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="px-4 py-2 font-medium">
                    Diámetro máximo de la llanta
                  </td>
                  <td className="px-4 py-2">37"</td>
                  <td className="px-4 py-2">37"</td>
                  <td className="px-4 py-2">37"</td>
                  <td className="px-4 py-2">37"</td>
                </tr>
                <tr className="border-b">
                  <td className="px-4 py-2 font-medium">
                    Velocidad de tornamesa
                  </td>
                  <td className="px-4 py-2">6 rpm</td>
                  <td className="px-4 py-2">6 rpm</td>
                  <td className="px-4 py-2">6 rpm</td>
                  <td className="px-4 py-2">6 rpm</td>
                </tr>
                <tr className="border-b">
                  <td className="px-4 py-2 font-medium">
                    Presión de operación
                  </td>
                  <td className="px-4 py-2">116-145 psi</td>
                  <td className="px-4 py-2">116-145 psi</td>
                  <td className="px-4 py-2">116-145 psi</td>
                  <td className="px-4 py-2">116-145 psi</td>
                </tr>
                <tr className="border-b">
                  <td className="px-4 py-2 font-medium">Peso de máquina</td>
                  <td className="px-4 py-2">344 kg</td>
                  <td className="px-4 py-2">276 kg</td>
                  <td className="px-4 py-2">238 kg</td>
                  <td className="px-4 py-2">214 kg</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section className="bg-background text-foreground py-4 md:py-6 lg:py-8">
          <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8 px-4 sm:px-6 md:px-8 lg:px-12">
            <div className="flex items-center group">
              <img
                alt="Turboinflado"
                width="100"
                height="100"
                className="rounded-md mr-4 transition-transform duration-300 ease-in-out group-hover:scale-110"
                src={icon5Des}
                //   style="aspect-ratio: 100 / 100; object-fit: cover;"
                style={{ aspectRatio: 100 / 100, objectFit: "cover" }}
              />
              <div>
                <h3 className="text-lg font-bold">Turboinflado</h3>
                <p className="text-sm text-muted-foreground max-w-[600px] text-justify leading-relaxed">
                  Proporciona una ráfaga de aire a alta presión para un sellado
                  rápido y preciso de los neumáticos, ideal para neumáticos de
                  perfil bajo y sin aire.
                </p>
              </div>
            </div>
            <div className="flex items-center group">
              <img
                alt="Destalonador con Protector"
                width="100"
                height="100"
                className="rounded-md mr-4 transition-transform duration-300 ease-in-out group-hover:scale-110"
                src={icon3Des}
                //   style="aspect-ratio: 100 / 100; object-fit: cover;"
                style={{ aspectRatio: 100 / 100, objectFit: "cover" }}
              />
              <div>
                <h3 className="text-lg font-bold">
                  Destalonador con Protector
                </h3>
                <p className="text-sm text-muted-foreground max-w-[600px] text-justify leading-relaxed">
                  {/* Powerful bead breaker with fingertip control and adjustable paddle. Includes protectors to avoid rim
            damage during tire removal. */}
                  Potente destalonador con control y paleta ajustable. Incluye
                  protectores para evitar daños en la llanta durante el
                  desmontaje del neumático.
                </p>
              </div>
            </div>
            <div className="flex items-center group">
              <img
                alt="Estructura Robusta"
                width="100"
                height="100"
                className="rounded-md mr-4 transition-transform duration-300 ease-in-out group-hover:scale-110"
                src={icon2Des}
                //   style="aspect-ratio: 100 / 100; object-fit: cover;"
                style={{ aspectRatio: 100 / 100, objectFit: "cover" }}
              />
              <div>
                <h3 className="text-lg font-bold">Estructura Robusta</h3>
                <p className="text-sm text-muted-foreground max-w-[600px] text-justify leading-relaxed">
                  Estructura resistente con pies de goma para mayor estabilidad.
                </p>
              </div>
            </div>
            <div className="flex items-center group">
              <img
                alt="Marcas de Medición"
                width="100"
                height="100"
                className="rounded-md mr-4 transition-transform duration-300 ease-in-out group-hover:scale-110"
                src={icon6Des}
                style={{ aspectRatio: 100 / 100, objectFit: "cover" }}
                //   style="aspect-ratio: 100 / 100; object-fit: cover;"
              />
              <div>
                <h3 className="text-lg font-bold">Marcas de Medición</h3>
                <p className="text-sm text-muted-foreground max-w-[600px] text-justify leading-relaxed">
                  {/* High-grip jaw protectors to prevent tire slippage. */}
                  Protectores de mandíbula de alto agarre para evitar el
                  deslizamiento de los neumáticos.
                </p>
              </div>
            </div>
            <div className="flex items-center group">
              <img
                alt="Brazo Oscilante"
                width="100"
                height="100"
                className="rounded-md mr-4 transition-transform duration-300 ease-in-out group-hover:scale-110"
                src={icon4Des}
                //   style="aspect-ratio: 100 / 100; object-fit: cover;"
                style={{ aspectRatio: 100 / 100, objectFit: "cover" }}
              />
              <div>
                <h3 className="text-lg font-bold">Brazo Oscilante</h3>
                <p className="text-sm text-muted-foreground max-w-[600px] text-justify leading-relaxed">
                  El brazo oscilante facilita el montaje y desmontaje.
                </p>
              </div>
            </div>
            <div className="flex items-center group">
              <img
                alt="Cabezal con Polímero"
                width="100"
                height="100"
                className="rounded-md mr-4 transition-transform duration-300 ease-in-out group-hover:scale-110"
                src={icon1Des}
                //   style="aspect-ratio: 100 / 100; object-fit: cover;"
                style={{ aspectRatio: 100 / 100, objectFit: "cover" }}
              />
              <div>
                <h3 className="text-lg font-bold">Cabezal con Polímero</h3>
                <p className="text-sm text-muted-foreground max-w-[600px] text-justify leading-relaxed">
                  Evita daños en rines delicados.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Call To Action */}
      <CallToAction
        title={
          "¡Mantén una actitud positiva, sé persistente, y lograrás grandes cosas!"
        }
        subtitle={
          "Descubre cómo las herramientas Hantec pueden ayudarte a alcanzar el éxito en ventas."
        }
        className={"max-w-4xl mx-auto px-4 py-8 space-y-8"}
      />

      {/* Related Articles */}
      <RelatedArticles
        className={"max-w-4xl mx-auto px-4 py-8"}
        relatedArticles={relatedArticles}
      />

      <Footer />
    </>
  );
}

export default LandingDesmontadora;
